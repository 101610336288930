import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { funnelOutline } from 'ionicons/icons';

import './SortFilter.css';

const SortFilter = (props: { onValueChange(value: string): void, value?: string }) => {
    const [presentAlert] = useIonAlert();

    const isChecked = (value: string) => {
        if (!props.value && value !== 'none') return false;
        if (!props.value && value === 'none') return true;

        return props.value && props.value === value ? true : false;
    }

    return (
        <IonButton onClick={() =>
            presentAlert({
                header: 'Sort By Options',
                cssClass: 'custom-sort-by-alert',
                buttons: [
                    {
                        text: 'OK',
                        handler: (value: string) => props.onValueChange(value)
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    }
                ],
                inputs: [
                    {
                        label: 'None',
                        type: 'radio',
                        value: 'none',
                        checked: isChecked('none')
                    },
                    {
                        label: 'Product Code',
                        type: 'radio',
                        value: 'articleNo',
                        checked: isChecked('articleNo')
                    },
                    {
                        label: 'Barcode',
                        type: 'radio',
                        value: 'barcode',
                        checked: isChecked('barcode')
                    },
                    {
                        label: 'BMC',
                        type: 'radio',
                        value: 'bmc',
                        checked: isChecked('bmc')
                    },
                    {
                        label: 'Brand',
                        type: 'radio',
                        value: 'brand',
                        checked: isChecked('brand')
                    },
                    {
                        label: 'Description',
                        type: 'radio',
                        value: 'description',
                        checked: isChecked('description')
                    },
                    {
                        label: 'Master Description',
                        type: 'radio',
                        value: 'masterDescription',
                        checked: isChecked('masterDescription')
                    },
                    {
                        label: 'Secondary Barcode',
                        type: 'radio',
                        value: 'newBarcode',
                        checked: isChecked('newBarcode')
                    },
                    {
                        label: 'Unit Of Measure',
                        type: 'radio',
                        value: 'uom',
                        checked: isChecked('uom')
                    },
                    {
                        label: 'Unit Of Size',
                        type: 'radio',
                        value: 'uos',
                        checked: isChecked('uos')
                    }
                ],
            })
        }>
            <IonIcon src={funnelOutline} />
        </IonButton>
    );
}
export default SortFilter;
