import { CapacitorHttp } from "@capacitor/core";
import { ThunkAction } from "redux-thunk";
import { CONFIG } from "../../utils/AppConfig";
import { RootState } from "../store";
import { GET_PRODUCTS, ProductAction, Product, LOAD_MORE_PRODUCTS, FOUND_PRODUCT, User, Store, NOT_FOUND_PRODUCT, DISCONTINUED_PRODUCT, SEASONAL_PRODUCT, FILTERED_PRODUCT, PickerSession, SHOPPERS_TO_PURCHASE, IN_TRANSIT_AWAITING_PRODUCT, SEASONAL, DISCONTINUED, LOAD_MORE_FOUND_PRODUCTS, LOAD_MORE_SEASONAL_PRODUCTS, LOAD_MORE_DISCONTINUED_PRODUCTS, ARTICLE_UNOBTAINABLE_PRODUCT } from "../types";

export const getProducts = (args: {
    retailer: number,
    status: string,
    top?: number,
    skip?: number,
    orderBy?: string,
    onComplete?: () => void,
    onError?: () => void
}): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {
            let top = args.top == undefined ? 10 : args.top == 0 ? 10 : args.top;
            let skip = args.skip ?? 0;
            let retailer = args.retailer;
            let status = args.status ?? undefined;
            let orderBy = args.orderBy ?? '';

            if (!retailer) {
                console.error("Retailer cannot be null");
                throw "Retailer cannot be null";
            }

            if (!status) {
                console.error("Status cannot be null");
                throw "Status cannot be null";
            }

            let url = `${CONFIG.API_ENDPOINT_BACKEND}/Product?$top=${top}&$skip=${skip}&filter=retailerId eq ${retailer} and status eq '${status}'`;

            console.log(url);

            if (orderBy !== '' && orderBy !== undefined && orderBy !== 'none') {
                url += `&$orderBy=${orderBy} desc`;
            }

            const options = { url };
            const response = await CapacitorHttp.request({ ...options, method: 'GET' })
            const result: Product[] = response.data.result;

            console.log(result);

            dispatch({ type: GET_PRODUCTS, payload: result });
        } catch (err) {
            console.error(err);
            if (args.onError !== undefined) {
                args.onError();
            }
        } finally {
            if (args.onComplete !== undefined) {
                args.onComplete();
            }
        }
    }
}

export const addProducts = (args: {
    retailer: number,
    status: string,
    top?: number,
    skip?: number,
    orderBy?: string,
    onComplete?: () => void,
    onError?: () => void
}): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {
            let top = args.top == undefined ? 10 : args.top == 0 ? 10 : args.top;
            let skip = args.skip ?? 0;
            let status: string = args.status ?? undefined;
            let orderBy = args.orderBy ?? '';

            if (!status) {
                console.error("Status cannot be null");
                throw "Status cannot be null";
            }

            let url = `${CONFIG.API_ENDPOINT_BACKEND}/Product?$top=${top}&$skip=${skip}&filter=retailerId eq ${args.retailer} and status eq '${status}'`;

            if (orderBy !== '' && orderBy !== undefined && orderBy !== 'none') {
                url += `&$orderBy=${orderBy} desc`;
            }

            const options = { url };
            const response = await CapacitorHttp.request({ ...options, method: 'GET' })
            const result: Product[] = response.data.result;

            switch (status) {
                case SHOPPERS_TO_PURCHASE:
                    dispatch({ type: LOAD_MORE_PRODUCTS, payload: result });
                    break;
                case IN_TRANSIT_AWAITING_PRODUCT:
                    dispatch({ type: LOAD_MORE_FOUND_PRODUCTS, payload: result });
                    break;
                case SEASONAL:
                    dispatch({ type: LOAD_MORE_SEASONAL_PRODUCTS, payload: result });
                    break;
                case DISCONTINUED:
                    dispatch({ type: LOAD_MORE_DISCONTINUED_PRODUCTS, payload: result });
                    break;
                default:
                    break;
            }

        } catch (err) {
            console.error(err);
            if (args.onError !== undefined) {
                args.onError();
            }
        } finally {
            if (args.onComplete !== undefined) {
                args.onComplete();
            }
        }
    }
}

export const productFound = (product: Product, user: User, store: Store, pickerSession: PickerSession, onComplete?: () => void): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {

            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/api/ProductAction`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    productId: product.id,
                    statusId: 2,
                    userId: user.id,
                    storeId: store.id,
                    sessionId: pickerSession.id,
                    message: `${product.barcode} In Transit Awaiting Product`
                })
            };

            const response = await CapacitorHttp.request({ ...options, method: 'POST' })
            const result: Product = response.data.result;

            dispatch({
                type: FOUND_PRODUCT,
                payload: result
            });
        } catch (err) {
            throw 'Cloud not mark product as found!';
        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const productNotFound = (product: Product, user: User, store: Store, pickerSession: PickerSession, onComplete?: () => void): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {
            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/api/ProductAction`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    productId: product.id,
                    statusId: 5,
                    userId: user.id,
                    storeId: store.id,
                    sessionId: pickerSession.id,
                    message: `${product.barcode} Not Found`
                })
            };

            const response = await CapacitorHttp.request({ ...options, method: 'POST' })
            const result: Product = response.data.result;

            result.statusId === 110
                ? dispatch({ type: ARTICLE_UNOBTAINABLE_PRODUCT, payload: result })
                : dispatch({ type: NOT_FOUND_PRODUCT, payload: result });

        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const productDiscontinued = (product: Product, user: User, store: Store, pickerSession: PickerSession, message?: string, onComplete?: () => void): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {
            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/api/ProductAction`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    productId: product.id,
                    statusId: 4,
                    userId: user.id,
                    storeId: store.id,
                    sessionId: pickerSession.id,
                    message: !message ? `${product.barcode} Discontinued` : message[0]
                })
            };

            const response = await CapacitorHttp.request({ ...options, method: 'POST' })
            const result: Product = response.data.result;

            dispatch({
                type: DISCONTINUED_PRODUCT,
                payload: result
            });
        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const productSeasonal = (product: Product, user: User, store: Store, pickerSession: PickerSession, message?: string, onComplete?: () => void): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {

            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/api/ProductAction`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    productId: product.id,
                    statusId: 4,
                    userId: user.id,
                    storeId: store.id,
                    sessionId: pickerSession.id,
                    message: !message ? `${product.barcode} Discontinued` : message[0]
                })
            };

            const response = await CapacitorHttp.request({ ...options, method: 'POST' })
            const result: Product = response.data.result;

            dispatch({
                type: SEASONAL_PRODUCT,
                payload: result
            });
        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const addfilteredProducts = (filterlist: Product[], onComplete?: () => void): ThunkAction<void, RootState, null, ProductAction> => {
    return async dispatch => {
        try {
            dispatch({
                type: FILTERED_PRODUCT,
                payload: filterlist
            });
        } catch (err) {
            console.error(err);
        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

