import { CapacitorHttp } from "@capacitor/core";
import { ThunkAction } from "redux-thunk";
import { CONFIG } from "../../utils/AppConfig";
import { RootState } from "../store";
import { GET_STORES, Store, StoreAction, SetStoreAction, SET_STORE, GetStoreAction, GET_STORE } from "../types";

export const getStores = (onComplete ?: () => void, province: string = '', top: number = 5, skip: number = 0): ThunkAction<void, RootState, null, StoreAction> => {
    return async dispatch => {
        try {
            //Fix null or empty values
            top = top === 0 ? 5 : top;
            let filter = province === 'All' || province === '' ? '' : `&$filter= province eq '${province}'`;
            console.log(province);
            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/Stores?$top=${top}${filter}`,
            };

            const response = await CapacitorHttp.request({ ...options, method: 'GET' })
            const storeResponse: Store[] = response.data.result;

            dispatch({
                type: GET_STORES,
                payload: storeResponse
            });
        } catch (err) {
            console.log(err);
        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const setStore = (store: Store, onComplete?: () => void): ThunkAction<void, RootState, null, SetStoreAction> => {
    return async dispatch => {
        try {
            dispatch({
                type: SET_STORE,
                store: store
            });
        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}
export const getStore = (onComplete?: () => void): ThunkAction<void, RootState, null, GetStoreAction> => {
    return async dispatch => {
        try {
            dispatch({
                type: GET_STORE,
                payload: null
            });
        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}