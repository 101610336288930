import { createStore, applyMiddleware, combineReducers, CombinedState } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import authReducer from './reducers/authReducer';
import productReducer from './reducers/productReducer';
import storeReducer from './reducers/storeReducer';
import sessionReducer from './reducers/sessionReducer';

import { authCheck } from './middleware/authCheck';
import { logger } from './middleware/logger';
import { crashReporter } from './middleware/crashReporter';

import { CombinedStates, StateActions, SET_LOGOUT } from './types';


const productPersistConfig = {
    key: 'products',
    storage: storage,
    whitelist: ['notFound']
}

const rootReducer = combineReducers({
    auth: authReducer,
    product: persistReducer(productPersistConfig, productReducer),
    store: storeReducer,
    session: sessionReducer
});

//const rootReducer = (state: CombinedStates | undefined, action: StateActions) => {
//    if (action.type === SET_LOGOUT) {
//        //storage.removeItem('persist:root');

//        return appReducer(undefined, action);
//    }

//    return appReducer(state, action)
//};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'store', 'session']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(authCheck, logger, crashReporter, thunk))
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export default store;







