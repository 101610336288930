import { IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../redux/store";
import { chevronDownCircleOutline } from "ionicons/icons";
import { getProducts } from "../../redux/actions/productActions";

import './ProductRefresher.css';

const ProductRefresher = ({ refreshCount, status }: { refreshCount: number, status: string }) => {
    const { selectedStore } = useSelector((state: RootState) => state.store);
    const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

    async function doListRefresh(event: CustomEvent<RefresherEventDetail>) {
        if (refreshCount === undefined) {
            return;
        }
        const options = {
            retailer: selectedStore!.companyId,
            status: status,
            top: refreshCount,
            onComplete: () => event.detail.complete()
        };

        dispatch(getProducts(options));
    }

    return (
        <IonRefresher className="product-refresher" slot="fixed" onIonRefresh={doListRefresh} disabled={false}>
            <IonRefresherContent
                pullingIcon={chevronDownCircleOutline}
                pullingText="Pull to refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing...">
            </IonRefresherContent>
        </IonRefresher>
    );
}

export default ProductRefresher;