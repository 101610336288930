import React from 'react';
import moment from 'moment';
import preval from 'preval.macro';
import packageJson from '../../../package.json';

import './BuildWriter.css';


const buildTimestamp = preval`module.exports = new Date().getTime();`;

const BuildWriter: React.FC<{}> = () => {
    const getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

        return formattedDate;
    };

    return (
        <div className="container">
            <strong></strong>
            <p>
                {packageJson.version}{'.'}{buildTimestamp}{' '}{'('}{getDateString()}{')'}
            </p>
        </div>
    );
};

export default BuildWriter;
