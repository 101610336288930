import { ThunkAction } from 'redux-thunk';
import { AuthAction, SET_USER, SET_LOADING, SET_LOGOUT, SET_ERROR, SET_SUCCESS, LoginData, User, Claims, Token, StateActions } from '../types';
import { persistor, RootState } from '../store'
import { CONFIG } from '../../utils/AppConfig';
import { CapacitorHttp } from '@capacitor/core';
import { endSession } from '../actions/sessionActions';

export const setLoading = (value: boolean): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: value
        })
    }
}

export const login = (data: LoginData, onSuccess: () => void, onError: () => void): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            const options = {
                url: `${CONFIG.API_ENDPOINT_AUTH}/token?username=${data.username}&password=${data.password}`
            };

            const response = await CapacitorHttp.request({ ...options, method: 'POST' });
            const auth: User = response.data;

            const claimOptions = {
                url: `${CONFIG.API_ENDPOINT_AUTH}/account/details`,
                headers: {
                    'Authorization': `bearer ${auth.token}`
                }
            };

            const claimResponse = await CapacitorHttp.request({ ...claimOptions, method: 'GET' })
            const claims: Claims[] = claimResponse.data;

            if (claims.length > 0) {
                auth.id = claims.find((c) => c.type == "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier")!.value
                auth.claims = claims;
            }

            if (auth) {
                dispatch({
                    type: SET_USER,
                    payload: auth
                });
            }
            else {
                onError();
                dispatch(setError('Failed to login...'));
            }
        } catch (error: unknown) {
            onError();
            if (error instanceof Error) {
                dispatch(setError(error.message));
            }
            else {
                dispatch(setError("Login failed with unknow error. Please see console for information!"));
            }
        } finally {
            dispatch(setLoading(false));
        }
    }
}

export const logout = (onComplete?: () => void): ThunkAction<void, RootState, null, AuthAction> => {
    return async (dispatch)=> {
        try {
            dispatch(setLoading(true));

            dispatch(endSession(() => {

                persistor.flush().then(() => {
                    return persistor.purge().then(() => {
                        dispatch({ type: SET_LOGOUT });
                        window.location.reload();
                    });
                });

            }));
            //dispatch({ type: SET_LOGOUT });
        } catch (error: unknown) {
            console.error(`Login error: ${error}`);
            dispatch({ type: SET_LOGOUT });

            if (error instanceof Error) {
                dispatch(setError(error.message));
            }
            dispatch(setError("Login failed with unknow error. Please see console for information!"));
        } finally {
            dispatch(setLoading(false));
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}

export const setError = (msg: string): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: SET_ERROR,
            payload: msg
        })
    }
}

export const setSuccess = (msg: string): ThunkAction<void, RootState, null, AuthAction> => {
    return dispatch => {
        dispatch({
            type: SET_SUCCESS,
            payload: msg
        })
    }
}