import { IonItem, IonList } from "@ionic/react"
import { MutableRefObject } from "react"
import { Product } from "../../redux/types"
import ProductCard from "./card/ProductCard"
import Placeholder from "./Placeholder"

const ProductList = ({ loaded, productList, filteredList, disableAllActions }: { loaded: boolean, productList: Product[], filteredList: Product[], disableAllActions: MutableRefObject<boolean> }) => {
    return (
        <IonList role="list">
            {!loaded
                ? <Placeholder title="Loading Products" message="Please wait..." />
                : filteredList.length === 0
                    ? (
                        productList?.length !== 0
                            ? (
                                productList.map(
                                    (product: Product) => (
                                        <IonItem key={product.id} role="article">
                                            <ProductCard product={product} disableAllActions={disableAllActions} />
                                        </IonItem>
                                    )
                                )
                            ) : (
                                <Placeholder title="EMPTY" message="THERE ARE NO ITEMS IN THIS LIST" />
                            )
                    ) : (
                        filteredList.map(
                            (product: Product) => (
                                <IonItem key={product.id} role="article">
                                    <ProductCard product={product} disableAllActions={disableAllActions} />
                                </IonItem>
                            )
                        )
                    )
            }
        </IonList>
    );
}

export default ProductList;