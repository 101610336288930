import { Middleware } from 'redux'
import { RootState } from '../store'

export const logger: Middleware<{}, RootState> = store => next => action => {
    console.log('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState());
    console.log('User Expires At ', store.getState().auth.user?.expiration);
    return result;
}
