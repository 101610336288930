import { Route, Redirect, RouteProps } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from 'react-redux';


interface Props extends RouteProps {
    component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const { authenticated } = useSelector((state: RootState) => state.auth)

    return (
        <Route {...rest} render={props => authenticated ? <Component {...props} /> : <Redirect to="/login" />} />
    );
};

export default PrivateRoute;
