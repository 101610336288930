import { stat } from "fs";
import { SessionState, START_SESSION, END_SESSION, SessionAction } from "../types";

const initialState: SessionState = {
    pickerSession: null,
    sessionActive: false,
}

export default (state = initialState, action: SessionAction) => {
    switch (action.type) {
        case START_SESSION:
            return {
                ...state,
                pickerSession: action.payload,
                sessionActive: true
            }
        case END_SESSION:
            return {
                ...state,
                pickerSession: action.payload,
                sessionActive: false
            }
        default:
            return state;
    }
}