import { SET_LOGOUT } from "../types";


import { Middleware } from 'redux'
import { RootState } from '../store'

export const authCheck: Middleware<{}, RootState> = store => next => action => {
    try {
        if (typeof action !== "function") {
            return next(action);
        }

        const { user, authenticated } = store.getState().auth;

        if (!user) {
            console.debug('Failed to find user...');
            return next(action);
        }

        let expireAt = new Date(user.expiration);
        let currentDate = new Date(Date.now());

        if (authenticated && expireAt <= currentDate) {
            console.debug('Time expired...');
            return action(store.dispatch({ type: SET_LOGOUT }), store.getState);
        }

        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        throw err
    }
}