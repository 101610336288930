import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useDispatch, useSelector } from 'react-redux';

import Menu from './components/menu/Menu';
import Home from './pages/Home';
import Login from './pages/Login'
import Notifications from './pages/Notifications';
import Stores from './pages/Stores';
import Logout from './pages/Logout';

import PublicRoute from './components/Router/PublicRoute'
import PrivateRoute from './components/Router/PrivateRoute';

import { RootState } from './redux/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import { Redirect, Route } from 'react-router';

setupIonicReact();

const App: React.FC = () => {
    const { authenticated } = useSelector((state: RootState) => state.auth)
    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId="main">
                    {authenticated ? <Menu /> : <></>}
                    <IonRouterOutlet id="main">
                        <PrivateRoute component={Stores} path="/" exact={true} />
                        <PrivateRoute component={Stores} path="/page/stores" exact={true} />
                        <PrivateRoute component={Home} path="/page/home" exact={true} />
                        <PrivateRoute component={Notifications} path="/page/notifications" exact={true} />
                        <PrivateRoute component={Logout} path="/logout" exact={true} />
                        <PublicRoute component={Login} path="/login" exact={true} />
                        <PrivateRoute component={Stores} exact={true} />
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
};
export default App;
