import { CapacitorHttp } from "@capacitor/core";
import { ThunkAction } from "redux-thunk";
import { CONFIG } from "../../utils/AppConfig";
import { RootState } from "../store";
import { PickerSession, SessionAction, START_SESSION, END_SESSION, Store, RESET_NOT_FOUND_PRODUCT, StateActions, GET_PRODUCTS } from "../types";
import { Geolocation } from '@capacitor/geolocation';


export const startSession = (store: Store, userId: string, onComplete?: () => void): ThunkAction<void, RootState, null, SessionAction> => {
    return async dispatch => {
        try {
            let date = new Date().toISOString();
            const location = await Geolocation.getCurrentPosition();

            const model = {
                "id": 0,
                "userId": userId,
                "storeId": store.id,
                "startTime": date,
                "endTime": null,
                "latitude": location.coords.latitude,
                "longitude": location.coords.longitude,
                "store": store
            }


            const options = {
                url: `${CONFIG.API_ENDPOINT_BACKEND}/PickerSession`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: model
            };

            console.log(options)

            const response = await CapacitorHttp.request({ ...options, method: 'POST' })
            const result: PickerSession = response.data.result.entity;

            dispatch({
                type: START_SESSION,
                payload: result,
                sessionActive: true
            });
        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}
export const endSession = (onComplete?: () => void): ThunkAction<void, RootState, null, StateActions> => {
    return async (dispatch, getState) => {
        try {
            if (getState().session.sessionActive) {
                const options = {
                    url: `${CONFIG.API_ENDPOINT_BACKEND}/PickerSession?id=${getState().session.pickerSession?.id}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        "endTime": new Date().toISOString()
                    })
                };

                const response = await CapacitorHttp.request({ ...options, method: 'PATCH' })
                const result: PickerSession = response.data.result.entity;

                dispatch({ type: END_SESSION, payload: result, sessionActive: false });
                dispatch({ type: RESET_NOT_FOUND_PRODUCT, payload: [] });
            }

        } catch (err) {

        } finally {
            if (onComplete !== undefined) {
                onComplete();
            }
        }
    }
}