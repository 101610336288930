import { IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonImg, IonFooter, IonToolbar, IonHeader, IonButton, IonIcon, useIonLoading } from '@ionic/react';
import { closeOutline, storefrontOutline, notificationsOutline, logOutOutline } from 'ionicons/icons';
import { useDispatch } from 'react-redux';

import { Redirect, useHistory, useLocation } from 'react-router-dom';

import BuildWriter from '../../components/versioning/BuildWriter';
import { logout } from '../../redux/actions/authActions';
import './Menu.css';

interface AppPage {
    url: string;
    icon: string;
    title: string;
    autoHide: boolean;
}

const appPages: AppPage[] = [
    {
        title: 'Stores',
        url: '/page/stores',
        icon: storefrontOutline,
        autoHide: false
    },
    {
        title: 'Notifications',
        url: '/page/notifications',
        icon: notificationsOutline,
        autoHide: false
    }
];

const Menu: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const [present, dismiss] = useIonLoading();

    const handleLogout = () => {
        present({
            message: 'Signing you out...',
            duration: 2000,
            onWillDismiss: () => {
                dispatch(logout(() => {
                    <Redirect to='/login' />
                   // history.replace('/login');
                }));
            }
        })
    }

    return (
        <IonMenu contentId="main" className="main-menu" type="overlay">
            <IonHeader className="ion-no-border  menu-header-logo">
                <IonToolbar className="menu-heander-logo">
                    <IonMenuToggle slot="start">
                        <IonButton>
                            <IonIcon src={closeOutline} />
                        </IonButton>
                    </IonMenuToggle>
                    <IonImg src="assets/logo_new.png" alt="Pickers Logo" className="logo-img menu-logo" />
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList id="menu-list">
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={appPage.autoHide}>
                                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <IonIcon slot="start" icon={appPage.icon} />
                                    <IonLabel className="menuText fw-600">{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonMenuToggle key={-1} autoHide={false}>
                        <IonItem onClick={handleLogout} routerDirection="none" lines="none" detail={false}>
                            <IonIcon slot="start" icon={logOutOutline} />
                            <IonLabel className="menuText fw-600">Logout</IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                </IonList>
            </IonContent>
            <IonFooter>
                <IonToolbar className="menu-footer-logo">
                    <BuildWriter />
                </IonToolbar>
            </IonFooter>
        </IonMenu>
    );
};

export default Menu;
