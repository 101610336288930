import {
    IonButton,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonHeader,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    useIonViewDidEnter,
    IonLabel,
    IonIcon,
    IonSpinner
} from '@ionic/react';
import { atOutline, lockClosedOutline, logInOutline, alertCircleOutline, filter, chevronDownCircleOutline, mapOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RefresherEventDetail } from '@ionic/core';
import { CapacitorHttp } from '@capacitor/core';

import StoreCard from '../components/stores/StoreCard';
import { RootState } from '../redux/store';

import AppHeader from '../components/header/AppHeader';
import useFixIonPageReentry from '../hooks/useFixIonPageReentry';

import { Store } from '../redux/types';

import { getStores } from '../redux/actions/storeActions';

import './Stores.css';
import { CONFIG } from '../utils/AppConfig';

const Stores: React.FC<{}> = () => {
    const pageRef = useFixIonPageReentry();
    const retailerRef = useRef<HTMLIonSelectElement>(null);
    const dispatch = useDispatch<any>();
    interface StoreLocation {
        Province: string
    };
    const [province, setProvince] = useState("All");
    const [locations, setLocations] = useState<StoreLocation[]>();

    const { stores, storesLoaded } = useSelector((state: RootState) => state.store);

    useIonViewDidEnter(async () => {
        await loadProvinces();
        dispatch(getStores(() => { }));
    });

    async function doListRefresh(event: CustomEvent<RefresherEventDetail>) {
        //const refreshCount: number = stores.length;
        await loadProvinces();
        setProvince("All");
        dispatch(getStores(() => {
            console.debug('List Refresh...');

            event.detail.complete();
        }));
    }

    const reloadStores = (obj: any) => {
        let val = obj.detail.value;
        console.log(obj)
        setProvince(val);
        dispatch(getStores(() => {
            console.debug(`Loading ${val} stores...`);
        }, val));
    }

    const loadProvinces = async () => {
        const options = {
            url: `${CONFIG.API_ENDPOINT_BACKEND}/api/Stores?$apply=groupby((province))`,
        };

        const response = await CapacitorHttp.request({ ...options, method: 'GET' })
        const result: StoreLocation[] = response.data.result;

        setLocations(result)
    }

    return (
        <IonPage>
            <AppHeader />
            <IonContent className="ion-padding">
                <IonRefresher slot="fixed" onIonRefresh={doListRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <IonList>
                    <IonItem lines="none">
                        <IonIcon icon={mapOutline} slot="start"></IonIcon>
                        <IonLabel>Province</IonLabel>
                        <IonSelect interface="action-sheet" ref={retailerRef} onIonChange={(val) => reloadStores(val)} selectedText={province}>
                            <IonSelectOption value="All">All</IonSelectOption>
                            {
                                locations?.map((location: StoreLocation, index) => (
                                    <IonSelectOption value={location.Province} key={index}>{location.Province}</IonSelectOption>
                                ))
                            }
                        </IonSelect>
                    </IonItem>
                </IonList>
                {
                    storesLoaded ?
                        stores.map((store: Store) => (
                            <StoreCard key={store.id} store={store}></StoreCard>))
                        :
                        <div className="text-center">
                            <IonSpinner name="dots"></IonSpinner>
                        </div>

                }

            </IonContent>
        </IonPage>
    );
};

export default Stores;
