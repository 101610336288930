import React, { PropsWithChildren } from 'react';
import { IonHeader, IonImg, IonMenuButton, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';

import './AppHeader.css';

const AppHeader: React.FC<PropsWithChildren> = (props) => {
    return (
        <IonHeader className="app-page-header">
            <IonToolbar>
                <IonMenuButton slot="start" />
                <IonTitle slot="start">
                    <IonImg src="assets/logo_new.png" alt="Pickers Logo" className="logo-img" />
                </IonTitle>
                {props.children}
            </IonToolbar>
        </IonHeader>
    );
};

export default AppHeader;
