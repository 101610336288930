import React, { useEffect, useState } from 'react';

import { IonItemOptionsCustomEvent } from '@ionic/core';
import { IonFab, IonFabButton, IonIcon, useIonLoading } from '@ionic/react';
import { CapacitorHttp } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { play, stop } from 'ionicons/icons';

import store, { RootState } from '../../redux/store';
import { PickerSession, SessionState } from '../../redux/types';
import { startSession, endSession } from '../../redux/actions/sessionActions';
import { useHistory } from 'react-router';

const SessionButton: React.FunctionComponent<{ }> = () => {
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const { sessionActive } = useSelector((state: RootState) => state.session);
    const { selectedStore } = useSelector((state: RootState) => state.store);
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);

    const [present, dismiss] = useIonLoading();

    useEffect(() => {
        return () => { dismiss(); }
    }, [loading]);

    const start = () => {

        setLoading(true);

        present({ message: 'Starting your session...' });

        dispatch(startSession(selectedStore!, user!.id, () => { dismiss(); }));

        setLoading(false);
    }

    return (
        (
            sessionActive ?
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="danger" onClick={() => dispatch(endSession(() => { }))}>
                        <IonIcon icon={stop} />
                    </IonFabButton>
                </IonFab>
                :
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="tertiary" onClick={start}>
                        <IonIcon icon={play} />
                    </IonFabButton>
                </IonFab>
            )
    );
};

export default SessionButton;