import React, { MutableRefObject } from 'react';
import { IonCard, IonCardContent, IonCol, IonGrid, IonImg, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonRow, useIonAlert, useIonLoading } from '@ionic/react';
import { IonItemOptionsCustomEvent } from '@ionic/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import { Product } from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import { productDiscontinued, productFound, productNotFound, productSeasonal } from '../../../redux/actions/productActions';

import './ProductCard.css';

const ProductCard: React.FunctionComponent<{ product: Product, disableAllActions: MutableRefObject<boolean> }> = ({ product, disableAllActions }) => {
    const dispatch = useDispatch<any>();
    const { user } = useSelector((state: RootState) => state.auth);
    const { selectedStore } = useSelector((state: RootState) => state.store);
    const { sessionActive, pickerSession } = useSelector((state: RootState) => state.session);
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();

    const hapticsImpactFeedback = async () => await Haptics.impact({ style: ImpactStyle.Medium });

    const onSwipe = async (event: IonItemOptionsCustomEvent<any>) => {
        if (!sessionActive) {
            presentAlert({
                header: 'No Session Started',
                message: 'Please start your session before performing any actions on the products',
                buttons: ['OK'],
            })
            return;
        }

        if (disableAllActions.current) {
            presentAlert({
                header: 'Not allowed',
                message: 'The following action is only available in shopper to purchase',
                buttons: ['OK'],
            })
            return;
        }

        switch (event.detail.side) {
            case "start":
                present({ message: 'Marking product as found...' });
                dispatch(productFound(product, user!, selectedStore!, pickerSession!, async () => { await hapticsImpactFeedback(); dismiss(); }));
                break;
            case "end":
                present({ message: 'Marking product as not found...' });
                dispatch(productNotFound(product, user!, selectedStore!, pickerSession!, async () => { await hapticsImpactFeedback(); dismiss(); }));
                break;
            default:
                break;
        }
    }

    const performAction = async (status: string) => {
        switch (status) {
            case "found":
                present({ message: 'Marking product as found...' });
                dispatch(productFound(product, user!, selectedStore!, pickerSession!, async () => {
                    await hapticsImpactFeedback();
                    dismiss();
                }));
                break;
            case "notfound":
                present({ message: 'Marking product as not found...' });
                dispatch(productNotFound(product, user!, selectedStore!, pickerSession!, async () => {
                    await hapticsImpactFeedback();
                    dismiss();
                }));
                break;
            case "discontinued":
                presentAlert({
                    header: 'Please enter a reason',
                    cssClass: 'custom-input-alert',
                    buttons: [
                        {
                            text: 'OK',
                            handler: (message: string) => handleDiscontinued(message)
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel'
                        }
                    ],
                    inputs: [
                        {
                            type: 'textarea',
                            placeholder: 'A description of why it was discontinued',
                        }
                    ],
                })

                break;
            case "seasonal":
                presentAlert({
                    header: 'Please enter a reason',
                    cssClass: 'custom-input-alert',
                    buttons: [
                        {
                            text: 'OK',
                            handler: (message: string) => handleSeasonal(message)
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel'
                        }
                    ],
                    inputs: [
                        {
                            type: 'textarea',
                            placeholder: 'A short description when it will be available',
                        }
                    ],
                })
                break;
            default:
                break;
        }
    }

    const handleDiscontinued = (message: string) => {
        present({ message: 'Marking product as discontinued...' });
        dispatch(productDiscontinued(product, user!, selectedStore!, pickerSession!, message, async () => {
            await hapticsImpactFeedback();
            dismiss();
        }));
    }

    const handleSeasonal = (message: string) => {
        present({ message: 'Marking product as seasonal...' });
        dispatch(productSeasonal(product, user!, selectedStore!, pickerSession!, message, async () => {
            await hapticsImpactFeedback();
            dismiss();
        }));
    }

    const handleComment = () => {
        presentAlert({
            header: 'Comment Services Unavailable',
            message: `Chat services is currently unavailable. You will be notified once the service is online.`,
            buttons: ['OK'],
            onWillPresent: async () => await hapticsImpactFeedback()
        });
    }

    return (
        <IonCard style={{ 'zIndex': '1' }}>
            <IonCardContent>
                <IonItemSliding>
                    <IonItemOptions side="start" onIonSwipe={onSwipe}>
                        <IonItemOption type="button" onClick={() => performAction('found')} color="found" disabled={!sessionActive || disableAllActions.current} expandable>
                            <IonImg slot="top" className="btnActionImages" src="/assets/buttons/found.svg" />
                        </IonItemOption>
                    </IonItemOptions>
                    <IonItem className="removeInnerBorderWidth">
                        <IonGrid>
                            <IonRow>
                                <IonCol><b className="textSecondary">BARCODE : </b> {product.barcode} </IonCol>
                                <IonCol><b className="textSecondary">PRODUCT CODE : </b> {product.articleNo}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol><b className="textSecondary">BRAND : </b> {product.brand}</IonCol>
                                <IonCol><b className="textSecondary">BMC : </b> {product.bmc}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol><b className="textSecondary">DESCRIPTION : </b> {product.description} ({product.uos} {product.uom})</IonCol>
                                <IonCol><b className="textSecondary">MASTER DESCRIPTION : </b> {product.masterDescription}</IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                    <IonItemOptions onIonSwipe={onSwipe}>
                        <IonItemOption type="button" onClick={() => performAction('notfound')} color="notfound" disabled={!sessionActive || disableAllActions.current} expandable>
                            <IonImg slot="top" className="btnActionImages" src="/assets/buttons/notfound.svg" />
                        </IonItemOption>
                        <IonItemOption type="button" onClick={() => performAction('discontinued')} color="discontinued" disabled={!sessionActive || disableAllActions.current}>
                            <IonImg slot="top" className="btnActionImages" src="/assets/buttons/discontinued.svg" />
                        </IonItemOption>
                        <IonItemOption type="button" onClick={() => performAction('seasonal')} color="seasonal" disabled={!sessionActive || disableAllActions.current}>
                            <IonImg slot="top" className="btnActionImages" src="/assets/buttons/seasonal.svg" />
                        </IonItemOption>
                        <IonItemOption type="button" onClick={handleComment} color="comment" disabled={!sessionActive || disableAllActions.current}>
                            <IonImg slot="top" className="btnActionImages" src="/assets/buttons/comment.svg" />
                        </IonItemOption>
                    </IonItemOptions>
                </IonItemSliding>
            </IonCardContent>
        </IonCard>
    );
};

export default ProductCard;
