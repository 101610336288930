import { IonButton, IonContent, IonPage, IonIcon, IonImg, IonInput, IonList, IonItem, useIonToast, useIonLoading, IonLoading, useIonAlert, isPlatform, getPlatforms, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { atOutline, lockClosedOutline, logInOutline } from 'ionicons/icons';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { login, setError } from '../redux/actions/authActions';
import { RootState } from '../redux/store';

import useFixIonPageReentry from '../hooks/useFixIonPageReentry';

import './Login.css';

const Login: React.FC<{}> = () => {
    const pageRef = useFixIonPageReentry();
    const username = useRef<HTMLIonInputElement>(null);
    const password = useRef<HTMLIonInputElement>(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch<any>();
    const { error } = useSelector((state: RootState) => state.auth);

    const [present, dismiss] = useIonLoading();
    const [presentAlert] = useIonAlert();
    const history = useHistory();

    useIonViewWillEnter(async () => {
        console.debug('***Your device is of type: ' + getPlatforms());

        if (!isPlatform('desktop') && !isPlatform('mobileweb')) {
            console.log(`***Your device is of type: ${getPlatforms()} and is now locked`);
            await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
        }
    });

    // Hooks
    useEffect(() => {
        return () => {
            if (error) {
                dispatch(setError(''));
            }
        }
    }, [error, dispatch]);

    useEffect(() => {
        if (!loading && error) {
            presentAlert({
                header: 'Login Failed',
                cssClass: 'custom-alert',
                message: `${error}`,
                buttons: ['OK'],
                onDidDismiss: (e: CustomEvent) => dispatch(setError(''))
            });
        }
    }, [loading, dispatch]);

    useEffect(() => {
        return () => { dismiss(); }
    }, [loading]);

    // Handlers
    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);

        present({ message: 'Signing in...' });

        dispatch(login({ username: username.current?.value as string, password: password.current?.value as string }, () => {
            if (!isPlatform('desktop')) {
                ScreenOrientation.unlock();
            }
            //setLoading(false);
            //history.replace("/page/stores");
            <Redirect push to="/page/stores" />
        }, () => {
            setLoading(false);
        }));
    }

    // Components
    return (
        <IonPage ref={pageRef}>
            <IonContent fullscreen className="login-container">
                <div id="bg">
                    <IonImg src="assets/login/logo_02.png" slot="fixed" />
                </div>
                <IonList lines="none" class="ion-margin">
                    <form onSubmit={handleLogin}>
                        <IonItem className="ion-margin removeInnerBorderWidth">
                            <IonIcon slot="start" src={atOutline} />
                            <IonInput placeholder="Enter Username" type="text" clearInput={false} ref={username} required></IonInput>
                        </IonItem>
                        <IonItem className="ion-margin removeInnerBorderWidth">
                            <IonIcon slot="start" src={lockClosedOutline} />
                            <IonInput placeholder="Enter Password" type="password" clearInput={false} ref={password} required></IonInput>
                        </IonItem>
                        <div>
                            <IonButton shape="round" strong={true} className="ion-margin-end" disabled={loading} type="submit">
                                SIGN IN <IonIcon src={logInOutline} slot="end"></IonIcon>
                            </IonButton>
                        </div>
                    </form>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Login;
