import React, { useEffect, useRef, useState } from 'react';
import { AnyAction } from "redux";
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IonContent, IonItem, IonPage, IonIcon, IonToolbar, IonSegment, IonSegmentButton, IonHeader, IonButtons, IonButton, IonSearchbar, SegmentCustomEvent, IonInfiniteScroll, IonInfiniteScrollContent, IonicSlides, useIonLoading, SearchbarChangeEventDetail } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import Fuse from 'fuse.js';

import { CapacitorHttp } from '@capacitor/core';
import { Swiper as SwiperInterface } from 'swiper/types';

import AppHeader from '../components/header/AppHeader';
import SortFilter from '../components/utils/SortFilter';
import ProductRefresher from '../components/products/ProductRefresher';
import useFixIonPageReentry from '../hooks/useFixIonPageReentry';
import SessionButton from '../components/session/SessionButton';
import ProductList from '../components/products/ProductList';

import { RootState } from '../redux/store';
import { DISCONTINUED, IN_TRANSIT_AWAITING_PRODUCT, NOT_FOUND, Product, SEASONAL, SHOPPERS_TO_PURCHASE } from '../redux/types';

import { addfilteredProducts, getProducts, addProducts } from '../redux/actions/productActions';

import { Swiper, SwiperSlide } from 'swiper/react';

import './Home.css';
import 'swiper/swiper.min.css';
import { CONFIG } from '../utils/AppConfig';

const Home: React.FC<{}> = () => {
    const pageRef = useFixIonPageReentry();
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const disableAllActions = useRef<boolean>(false);
    const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
    const { products, notFound, inTransit, discontinued, seasonal, filtered, productsLoaded } = useSelector((state: RootState) => state.product);
    const { selectedStore } = useSelector((state: RootState) => state.store);
    const { sessionActive } = useSelector((state: RootState) => state.session);
    const [productStatus, setProductStatus] = useState(SHOPPERS_TO_PURCHASE);
    const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
    const [isSearchbarShowing, toggleSearchbar] = useState(false);
    const [sortBy, setSortBy] = useState('none');
    const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
    const [present, dismiss] = useIonLoading();

    const segments = [SHOPPERS_TO_PURCHASE, NOT_FOUND, IN_TRANSIT_AWAITING_PRODUCT, DISCONTINUED, SEASONAL];

    let showSearchStyle = !isSearchbarShowing ? undefined : { display: "none" };
    let selectedRetailer = selectedStore!.companyId;

    useEffect(() => {
        cancelSearch();
        dispatch(getProducts({ retailer: selectedRetailer, status: productStatus }));
    }, [selectedRetailer]);
    useEffect(() => {
        cancelSearch();
        dispatch(getProducts({ retailer: selectedRetailer, status: productStatus }));
    }, [sessionActive]);
    useEffect(() => { if (products.length < 100) setInfiniteDisabled(false); }, [products]);
    useEffect(() => {
        setInfiniteDisabled(true);
        contentRef.current && contentRef.current.scrollToTop();

        disableAllActions.current = productStatus !== SHOPPERS_TO_PURCHASE;

        cancelSearch();
        dispatch(getProducts({
            retailer: selectedRetailer, status: productStatus
        }));
    }, [productStatus]);

    const handleSort = (value: string) => {
        const options = {
            retailer: selectedRetailer,
            status: productStatus,
            orderBy: value,
            onComplete: () => console.log(`Order value is : ${value}`)
        };

        setSortBy(value);
        dispatch(getProducts(options));
    }

    const handleStateSegementChange = (e: SegmentCustomEvent) => {
        const selectedSegment = segments.findIndex((el) => el === e.detail.value!);
        setProductStatus(e.detail.value!);
        swiperInstance?.slideTo(selectedSegment);
    }

    const performSearch = async () => {

        let searchQuery = "";
        const elem = document.getElementById("searchbar") as HTMLIonSearchbarElement;
        if (elem) searchQuery = elem.value!.toLowerCase();

        if (searchQuery.trim() !== "") {

            dispatch(addfilteredProducts([], () => { }));

            var data = [];
            var extend = true;

            switch (productStatus) {
                case 'Not Found': data = notFound; extend = false; break;
                case 'In Transit Awaiting Product': data = inTransit; break;
                case 'Discontinued': data = discontinued; break;
                case 'Seasonal': data = seasonal; break;
                default: data = products;
            }

            const fuse = new Fuse(data, {
                threshold: 0.2,
                shouldSort: true,
                keys: [
                    { name: "articleNo", weight: 1.0 },
                    { name: "barcode", weight: 1.0 },
                    { name: "bmc", weight: 0.25 },
                    { name: "brand", weight: 0.5 },
                    { name: "description", weight: 0.15 },
                    { name: "masterDescription", weight: 0.15 }
                ]
            });

            const result = fuse.search(searchQuery);

            if (result !== null && result.length !== 0) {
                const filteredList: Product[] = result.map(r => {

                    return r.item;
                });
                dispatch(addfilteredProducts(filteredList, () => { }))
            }
            else if (extend) {
                extendedSearch(searchQuery);
            }
        }
        else {
            return;
        }
    }

    const extendedSearch = async (searchQuery: string) => {
        console.log("extended search");
        present({ message: 'Searching...' });

        const options = {
            url: `${CONFIG.API_ENDPOINT_BACKEND}/api/product?$filter=retailerId eq ${selectedRetailer} and status eq '${productStatus}'`,
        };

        const response = await CapacitorHttp.request({ ...options, method: 'GET' })
        const allproducts: Product[] = response.data.result;

        const fuse = new Fuse(allproducts, {
            threshold: 0.2,
            shouldSort: true,
            keys: [
                { name: "articleNo", weight: 1.0 },
                { name: "barcode", weight: 1.0 },
                { name: "bmc", weight: 0.25 },
                { name: "brand", weight: 0.5 },
                { name: "description", weight: 0.15 },
                { name: "masterDescription", weight: 0.15 }
            ]
        });

        const result = fuse.search(searchQuery);

        if (result !== null && result.length !== 0) {
            const filteredList: Product[] = result.map(r => {

                return r.item;
            });

            dispatch(addfilteredProducts(filteredList, () => { dismiss(); }))
        }
        else {
                dispatch(addfilteredProducts([], () => { dismiss(); }))
        }
    }

    const cancelSearch = async () => {
        toggleSearchbar(false);
        console.log('canceled');
        dispatch(addfilteredProducts([], () => { }))
    }

    const loadDataOnScroll = async (event: any) => {
        let arrayCounts = [products.length, inTransit.length, discontinued.length, seasonal.length]

        let skip = arrayCounts.find(f => f !== 0) ?? 0;

        const options = {
            retailer: selectedStore!.companyId,
            status: productStatus,
            top: 10,
            skip: skip,
            orderBy: sortBy,
            onComplete: () => event.target.complete()
        };

        dispatch(addProducts(options));
    }

    return (
        <IonPage className="homePage" ref={pageRef}>
            <AppHeader>
                {isSearchbarShowing
                    ? (
                        <IonHeader>
                            <IonToolbar>
                                <IonSearchbar id="searchbar" placeholder="Quick Search for Products..." debounce={1000} onIonChange={performSearch} onIonCancel={() => cancelSearch()} showCancelButton="always"></IonSearchbar>
                            </IonToolbar>
                        </IonHeader>
                    ) : (
                        <IonButtons slot="end" className="btnHeader btnHeaderPadding" style={showSearchStyle}>
                            <IonButton onClick={() => toggleSearchbar(!isSearchbarShowing)}>
                                <IonIcon src={searchOutline} />
                            </IonButton>
                            <SortFilter onValueChange={(val) => handleSort(val)} value={sortBy} />
                        </IonButtons>
                    )
                }
            </AppHeader>
            <IonHeader>
                <IonHeader>
                    <IonToolbar>
                        <IonSegment value={productStatus} onIonChange={(event) => handleStateSegementChange(event)} scrollable={true}>
                            {segments.map((segment, idx) => (
                                <IonSegmentButton key={idx} value={segment}>
                                    <IonItem className="removeInnerBorderWidth child-background-transperent">
                                        {segment}
                                    </IonItem>
                                </IonSegmentButton>
                            ))}
                        </IonSegment>
                    </IonToolbar>
                </IonHeader>
            </IonHeader >
            <IonContent scrollEvents={true} ref={contentRef} className="main-content-wrapper" fullscreen>
                <ProductRefresher refreshCount={products?.length} status={productStatus} />
                <Swiper modules={[IonicSlides]} freeMode={false} noSwiping={true} allowTouchMove={false} onSwiper={(sw) => setSwiperInstance(sw)}>
                    <SwiperSlide>
                        <ProductList loaded={productsLoaded} productList={products} filteredList={filtered} disableAllActions={disableAllActions} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ProductList loaded={productsLoaded} productList={notFound} filteredList={filtered} disableAllActions={disableAllActions} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ProductList loaded={productsLoaded} productList={inTransit} filteredList={filtered} disableAllActions={disableAllActions} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ProductList loaded={productsLoaded} productList={discontinued} filteredList={filtered} disableAllActions={disableAllActions} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ProductList loaded={productsLoaded} productList={seasonal} filteredList={filtered} disableAllActions={disableAllActions} />
                    </SwiperSlide>
                </Swiper>
                <IonInfiniteScroll onIonInfinite={loadDataOnScroll} threshold="100px" disabled={isInfiniteDisabled}>
                    <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more data..." ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <SessionButton></SessionButton>
            </IonContent>
        </IonPage>
    );
};

export default Home;
