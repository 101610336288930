import { Route, Redirect, RouteProps } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from 'react-redux';


interface Props extends RouteProps {
    component: any;
}

const PublicRoute = ({ component: Component, ...rest }: any) => {
    const { authenticated } = useSelector((state: RootState) => state.auth)

    console.log('--------------');
    console.log(rest);
    console.log('--------------');

    return (
        <Route {...rest} render={props => !authenticated ? <Component {...props} /> : <Redirect to="/page/stores" />} />
    );
};

export default PublicRoute;
