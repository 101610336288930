import React, { useEffect, useState } from 'react';
import { IonPage, IonLoading, IonContent, isPlatform, getPlatforms, useIonViewWillEnter } from '@ionic/react';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions/authActions';
import useFixIonPageReentry from '../hooks/useFixIonPageReentry';
import { Link } from "react-router-dom";

const Logout: React.FC<{}> = () => {
    const pageRef = useFixIonPageReentry();
    const [showLoading, setShowLoading] = useState(false);
    const dispatch = useDispatch<any>();
    const history = useHistory();

    useIonViewWillEnter(async () => {
        setShowLoading(true);
        if (!isPlatform('desktop') && !isPlatform('mobileweb')) {
            await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
        }
    });

    const handleLogout = () => {
        dispatch(logout(() => {
            setShowLoading(false)
        }));
    }

    return (
        <IonPage ref={pageRef}>
            <IonContent>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => history.push('/login')}
                    onWillDismiss={handleLogout}
                    message={'Signing you out...'}
                    duration={5000}
                />
            </IonContent>
        </IonPage>
    );
};

export default Logout;
