import { useIonViewWillEnter } from "@ionic/react";
import React from "react";

/**
 * HACK: Prevents an IonPage from rendering blank when it re-enters.
 * Source: https://github.com/ionic-team/ionic/issues/19750
 */
const useFixIonPageReentry = () => {
    const pageRef = React.useRef<any>();
    useIonViewWillEnter(() => {
        const pageElem = pageRef.current && pageRef.current.ref && pageRef.current.ref.current;
        if (pageElem) {
            pageElem.style.removeProperty("display");
        }
    });

    return pageRef;
};

export default useFixIonPageReentry;