import { type } from "os";

export const USER_STORAGE_KEY = 'USER_STORE_KEY';
export const SET_USER = 'SET_USER';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const LOAD_MORE_PRODUCTS = 'LOAD_MORE_PRODUCTS';
export const FOUND_PRODUCT = 'FOUND_PRODUCT';
export const LOAD_MORE_FOUND_PRODUCTS = 'LOAD_MORE_FOUND_PRODUCTS';
export const NOT_FOUND_PRODUCT = 'NOT_FOUND_PRODUCT';
export const ARTICLE_UNOBTAINABLE_PRODUCT = 'ARTICLE_UNOBTAINABLE_PRODUCT';
export const RESET_NOT_FOUND_PRODUCT = 'RESET_NOT_FOUND_PRODUCT';
export const LOAD_MORE_NOT_FOUND_PRODUCTS = 'LOAD_MORE_NOT_FOUND_PRODUCTS';
export const DISCONTINUED_PRODUCT = 'DISCONTINUED_PRODUCT';
export const LOAD_MORE_DISCONTINUED_PRODUCTS = 'LOAD_MORE_DISCONTINUED_PRODUCTS';
export const SEASONAL_PRODUCT = 'SEASONAL_PRODUCT';
export const LOAD_MORE_SEASONAL_PRODUCTS = 'LOAD_MORE_SEASONAL_PRODUCTS';
export const FILTERED_PRODUCT = 'FILTERED_PRODUCT';
export const REMOVE_FILTERED_PRODUCT = 'REMOVE_FILTERED_PRODUCT';

export const SHOPPERS_TO_PURCHASE = 'Shoppers to Purchase';
export const IN_TRANSIT_AWAITING_PRODUCT = 'In Transit Awaiting Product';
export const SEASONAL = 'Seasonal';
export const DISCONTINUED = 'Discontinued';
export const NOT_FOUND = 'Not Found';


export const GET_STORES = 'GET_STORES';
export const SET_STORE = 'SET_STORE';
export const GET_STORE = 'GET_STORE';

export const GET_SESSIONS = 'GET_SESSIONS';
export const START_SESSION = 'START_SESSION';
export const END_SESSION = 'END_SESSION';

// State Mangement
export interface CombinedStates {
    auth: AuthState;
    product: ProductState;
    store: StoreState;
    session: SessionState;
}

export type StateActions = AuthAction | ProductAction | StoreAction | SessionAction;

//auth
export interface Token {
    token: string;
    expiration: string;
}

export interface User {
    id: string,
    token: string;
    expiration: string;
    claims: Claims[];
}

export interface Claims {
    type: string;
    value: string;
}

export interface AuthState {
    user: User | null;
    authenticated: boolean;
    loading: boolean;
    error: string;
    success: string;
}

export interface LoginData {
    username: string;
    password: string;
}

interface SetUserAction {
    type: typeof SET_USER;
    payload: User;
}

interface SetLoadingAction {
    type: typeof SET_LOADING;
    payload: boolean;
}

interface LogoutAction {
    type: typeof SET_LOGOUT;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

export type AuthAction = SetUserAction | SetLoadingAction | LogoutAction | SetErrorAction | SetSuccessAction;

// Products
export interface Product {
    id: number;
    articleNo: string;
    barcode: string;
    bmc: string;
    brand: string;
    brandId: number;
    description: string;
    masterDescription: string;
    manufacturerId: number;
    newBarcode: string;
    retailerId: number;
    status: string;
    statusId: number;
    uom: string;
    uos: string;
}

export interface ProductState {
    products: Product[];
    filtered: Product[];
    notFound: Product[];
    inTransit: Product[];
    discontinued: Product[];
    seasonal: Product[];
    productsLoaded: boolean;
}

interface LoadMoreProductsAction {
    type: typeof LOAD_MORE_PRODUCTS;
    payload: Product[];
}

interface GetProductsAction {
    type: typeof GET_PRODUCTS;
    payload: Product[];
}

interface FoundProductActions {
    type: typeof FOUND_PRODUCT;
    payload: Product;
}

interface LoadMoreFoundProductActions {
    type: typeof LOAD_MORE_FOUND_PRODUCTS;
    payload: Product[];
}

interface NotFoundProductActions {
    type: typeof NOT_FOUND_PRODUCT;
    payload: Product;
}

interface ResetNotFoundProductAction {
    type: typeof RESET_NOT_FOUND_PRODUCT;
    payload: Product[];
}

interface ArticleUnobtainableProductActions {
    type: typeof ARTICLE_UNOBTAINABLE_PRODUCT;
    payload: Product;
}

interface DiscontinuedProductActions {
    type: typeof DISCONTINUED_PRODUCT;
    payload: Product;
}

interface LoadMoreDiscontinuedProductActions {
    type: typeof LOAD_MORE_DISCONTINUED_PRODUCTS;
    payload: Product[];
}

interface SeasonalProductActions {
    type: typeof SEASONAL_PRODUCT;
    payload: Product;
}

interface LoadMoreSeasonalProductActions {
    type: typeof LOAD_MORE_SEASONAL_PRODUCTS;
    payload: Product[];
}

interface AddFilteredAction {
    type: typeof FILTERED_PRODUCT;
    payload: Product[];
}

interface RemoveFilteredAction {
    type: typeof REMOVE_FILTERED_PRODUCT;
    payload: Product[];
}

export type ProductAction = LoadMoreProductsAction | GetProductsAction | FoundProductActions | LoadMoreFoundProductActions | NotFoundProductActions | ResetNotFoundProductAction | ArticleUnobtainableProductActions | DiscontinuedProductActions | LoadMoreDiscontinuedProductActions | SeasonalProductActions | LoadMoreSeasonalProductActions | AddFilteredAction | RemoveFilteredAction;

// Stores
export interface Store {
    id: number;
    companyId: number;
    division: string;
    province: string;
    siteCode: string;
    latitude: number;
    longitude: number;
    address: string;
    stock: Stock[];
}
export interface Stock {
    id: number;
    storeId: number;
    articleNo: string;
    barcode: string;
    description: string;
    uom: string;
    quantity: number
}
export interface StoreState {
    stores: Store[];
    storesLoaded: boolean;
    selectedStore: Store | null;
}

export interface GetStoresAction {
    type: typeof GET_STORES;
    payload: Store[];
}

export interface SetStoreAction {
    type: typeof SET_STORE,
    store: Store
}

export interface GetStoreAction {
    type: typeof GET_STORE,
    payload: Store | null
}

export type StoreAction = GetStoresAction | SetStoreAction | GetStoreAction;

// Sessions
export interface PickerSession {
    id: number,
    userId: string,
    storeId: number,
    startTime: string,
    endTime: string | null,
    latitude: number,
    longitude: number,
    store: Store
}

export interface StartSessionAction {
    type: typeof START_SESSION;
    payload: PickerSession;
    sessionActive: boolean;
}
export interface EndSessionAction {
    type: typeof END_SESSION;
    payload: PickerSession;
    sessionActive: boolean;
}

export interface SessionState {
    pickerSession: PickerSession | null;
    sessionActive: boolean;
}

export type SessionAction = StartSessionAction | EndSessionAction;