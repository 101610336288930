import { stat } from "fs";
import Stores from "../../pages/Stores";
import { StoreState, StoreAction, GET_STORES, SET_STORE, GET_STORE } from "../types";

const initialState: StoreState = {
    stores: [],
    storesLoaded: false,
    selectedStore: null
,
}

export default (state = initialState, action: StoreAction) => {
    switch (action.type) {
        case GET_STORES:
            return {
                ...state,
                stores: action.payload,
                storesLoaded: true
            }
        case SET_STORE:
            return {
                ...state,
                stores: state.stores,
                storesLoaded: true,
                selectedStore: action.store
            }
        case GET_STORE: 
            return {
                ...state,
                stores: state.stores,
                storesLoaded: true,
                selectedStore: state.selectedStore
            }
        default:
            return state;
    }
}