import React from 'react';

import { IonItemOptionsCustomEvent } from '@ionic/core';
import { IonCard, IonCardContent, IonCol, IonGrid, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail, IonRow, IonBadge, IonLabel, IonButton, IonIcon, IonImg, useIonAlert } from '@ionic/react';
import { CapacitorHttp } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { chevronDownCircleOutline, funnelOutline, playCircleOutline, searchOutline, cartOutline, storefront, storefrontOutline, chevronForward } from 'ionicons/icons';

import './StoreCard.css';
import { RootState } from '../../redux/store';
import { Store, StoreState } from '../../redux/types';
import { setStore } from '../../redux/actions/storeActions';
import { endSession } from '../../redux/actions/sessionActions';
import { useHistory } from 'react-router';

const StoreCard: React.FunctionComponent<{ store: Store }> = ({ store }) => {
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const { sessionActive, pickerSession } = useSelector((state: RootState) => state.session);
    const [presentAlert] = useIonAlert();

    const selectStore = (selectedStore: Store) => {
        if (sessionActive && selectedStore.id !== pickerSession!.storeId) {

            presentAlert({
                header: 'Warning!',
                subHeader: 'Session still active.',
                message: 'Please end your session before changing your store.',
                buttons: ['Close'],
            });
            return;
        }
        else {
            dispatch(setStore(selectedStore, () => { }));
            history.push("/page/home");
        }
    }

    return (
        <IonCard type="button" onClick={() => selectStore(store)}>
            <IonCardHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol size="1.3">
                            <IonThumbnail>
                                <IonIcon style={{ 'width': '100%', 'height': '100%' }} icon={storefrontOutline} />
                            </IonThumbnail>
                        </IonCol>
                        <IonCol>
                            <IonCardTitle className="fw-600">
                                {store.division} <IonBadge>{store.stock.length}</IonBadge>
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {store.address}
                            </IonCardSubtitle>
                        </IonCol>
                        <IonCol size="1">
                            <IonIcon style={{ 'width': '100%', 'height': '100%' }} icon={chevronForward} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardHeader>
        </IonCard>
    );
};

export default StoreCard;
