import { ProductState, ProductAction, GET_PRODUCTS, LOAD_MORE_PRODUCTS, FOUND_PRODUCT, NOT_FOUND_PRODUCT, DISCONTINUED_PRODUCT, SEASONAL_PRODUCT, FILTERED_PRODUCT, REMOVE_FILTERED_PRODUCT, LOAD_MORE_FOUND_PRODUCTS, LOAD_MORE_SEASONAL_PRODUCTS, LOAD_MORE_DISCONTINUED_PRODUCTS, RESET_NOT_FOUND_PRODUCT, ARTICLE_UNOBTAINABLE_PRODUCT } from "../types";

const initialState: ProductState = {
    products: [],
    notFound: [],
    inTransit: [],
    discontinued: [],
    seasonal: [],
    filtered: [],
    productsLoaded: false
}

export default (state = initialState, action: ProductAction) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                inTransit: action.payload.filter(p => p.statusId === 9),
                discontinued: action.payload.filter(p => p.statusId === 102),
                seasonal: action.payload.filter(p => p.statusId === 111),
                notFound: state.notFound,
                products: action.payload.filter(f => !state.notFound.some(s => s.id === f.id) && f.statusId === 105),
                productsLoaded: true
            }
        case LOAD_MORE_PRODUCTS:
            return {
                ...state,
                products: [...state.products.filter(f => !action.payload.some(s => s.id === f.id) && !state.notFound.some(s => s.id === f.id)), ...action.payload],
                notFound: state.notFound
            }
        case LOAD_MORE_FOUND_PRODUCTS:
            return {
                ...state,
                inTransit: [...state.inTransit.filter(f => !action.payload.some(s => s.id === f.id)), ...action.payload]
            }
        case LOAD_MORE_SEASONAL_PRODUCTS:
            return {
                ...state,
                seasonal: [...state.seasonal.filter(f => !action.payload.some(s => s.id === f.id)), ...action.payload]
            }
        case LOAD_MORE_DISCONTINUED_PRODUCTS:
            return {
                ...state,
                discontinued: [...state.discontinued.filter(f => !action.payload.some(s => s.id === f.id)), ...action.payload]
            }
        case FOUND_PRODUCT:
            return {
                ...state,
                products: state.products.filter(v => v.id !== action.payload.id),
                inTransit: [...state.inTransit, action.payload],
            }
        case DISCONTINUED_PRODUCT:
            return {
                ...state,
                products: state.products.filter(v => v.id !== action.payload.id),
                discontinued: [...state.discontinued, action.payload],
            }
        case SEASONAL_PRODUCT:
            return {
                ...state,
                products: state.products.filter(v => v.id !== action.payload.id),
                seasonal: [...state.seasonal, action.payload],
            }
        case NOT_FOUND_PRODUCT:
            return {
                ...state,
                products: state.products.filter(v => v.id !== action.payload.id),
                notFound: [...state.notFound, action.payload],
                productsLoaded: true
            }
        case ARTICLE_UNOBTAINABLE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(v => v.id !== action.payload.id),
                productsLoaded: true
            }
        case RESET_NOT_FOUND_PRODUCT:
            return {
                ...state,
                notFound: []
            }
        case FILTERED_PRODUCT:
            return {
                ...state,
                filtered: action.payload
            }
        case REMOVE_FILTERED_PRODUCT:
            return {
                ...state,
                filtered: []
            }
        default:
            return state;
    }
}