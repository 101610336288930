import { IonContent, IonHeader, IonButtons, IonPage, IonTitle, IonToolbar, IonMenuButton, IonImg } from '@ionic/react';
import AppHeader from '../components/header/AppHeader';
import useFixIonPageReentry from '../hooks/useFixIonPageReentry';

import "./Notifications.css"

const Notifications: React.FC<{}> = () => {
    const pageRef = useFixIonPageReentry();

    return (
        <IonPage ref={pageRef}>
            <AppHeader />
            <IonContent scrollEvents={false} fullscreen className="main-content-wrapper">
                <div className="notifications-empty-container">
                    <strong className="texttertiary">Notifications</strong>
                    <p className="texttertiary">You currently have no new notifications. Please check back later.</p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Notifications;
