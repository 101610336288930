import { IonItem } from "@ionic/react";
import React from "react";

const Placeholder = React.memo(({ title, message }: { title: string, message: string }) => {
    return (
        <IonItem key="-1" role="article" className="empty-container-item">
            <div className="empty-container-inner">
                <strong className="texttertiary">{title}</strong>
                <p className="texttertiary">{message}</p>
            </div>
        </IonItem>
    )
});

export default Placeholder;